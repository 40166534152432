import { mapGetters } from 'vuex'
import NavBar from '@/pages/lib/sections/NavBar/NavBar.vue'
import PublicFooter from '@/pages/lib/sections/PublicFooter/PublicFooter.vue'
import TxButton from '@/components/lib/TxButton/TxButton.vue'

import PromoSection from './PromoSection/PromoSection.vue'
import TechnoSection from './TechnoSection/TechnoSection.vue'
import ContactsSection from '@/pages/lib/sections/ContactsSection/ContactsSection.vue'

export default {
  components: {
    NavBar,
    PromoSection,
    ContactsSection,
    PublicFooter,
    TechnoSection,
    TxButton
  },
  computed: {
    ...mapGetters({
      config: 'config/config'
    })
  },
  methods: {
    handleAbout () {
      this.$router.push({
        path: this.createLocalizedLink('/about-us')
      })
    },
    createLocalizedLink (url) {
      if (url.startsWith('/private')) {
        return url
      }
      return this.getLanguageUrlPathPrefix() + url
    }
  }
}
