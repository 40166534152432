
export default {
  props: {
    title: String,
    items: Array,
    columnDirection: {
      type: Boolean,
      default: false
    }
  }
}
