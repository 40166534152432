
export default {
  components: {
  },
  data () {
    return {
    }
  },
  computed: {
    technoContent () {
      return [
        {
          class: 'defi',
          columnTitle: this.i18nSection('technologypageDeFiTitle'),
          columnContentTitle: this.i18nSection('technologypageAboutDeFiTitle'),
          columnContentText: this.i18nSection('technologypageAboutDeFiText')
        },
        {
          class: 'api',
          columnTitle: this.i18nSection('technologypageAPITitle'),
          columnContentTitle: this.i18nSection('technologypageAboutAPITitle'),
          columnContentText: this.i18nSection('technologypageAboutAPIText')
        }
      ]
    }
  },
  methods: {
  }
}
