import { mapState } from 'vuex'
import TxButton from '@/components/lib/TxButton/TxButton.vue'
import TxInlineSvg from '@/components/lib/TxInlineSvg/TxInlineSvg.vue'
import WithBackgroundImagePartial from '@/pages/lib/partials/WithBackgroundImagePartial/WithBackgroundImagePartial.vue'

export default {
  components: {
    TxButton,
    TxInlineSvg,
    WithBackgroundImagePartial
  },
  computed: {
    ...mapState({
      contacts: state => state.public.contacts,
      socials: state => state.public.socials
    }),
    sortedSocials () {
      return this.socials
        .slice(0)
        .sort((a, b) => a.sortOrder - b.sortOrder)
        .filter(socials => socials.showInAboutUs)
    }
  },
  methods: {
    getContactUrl (url) {
      return url.includes('@')
        ? `mailto:${url}`
        : url
    }
  }
}
